import React from "react";
import Layout from "../components/layout"
import {graphql, useStaticQuery, Link } from "gatsby"
import References from "../components/references"
import './horsegrid.css';

import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { convertToBgImage, BgImage } from "gbimage-bridge"
import BackgroundImage from 'gatsby-background-image'

function Reference ({data}) {
  const horseArrRef = data.allMarkdownRemark.edges.map(horses =>{
    return horses.node
  })

    return (
      <>
      <title>EC Horses </title>
       <Layout>
          <h1 className="page-title"> REFERENCES </h1>
          <p className="ref_p"> Below you can find some references from our breeding program, horses we have produced, and others in which we have facilitated the sale. For additional references please visit our Instagram page.</p>
          <div className="references-cards">
            {horseArrRef.map((horse) => {
              const image = getImage(horse.frontmatter.img)
              const bgImage = convertToBgImage(image)
              return (
                <>
                  <BgImage
                    image={image}
                    style= {{minWidth: 105,
                     maxHeight:505,
                     backgroundPosition: "top",
                      backgrounSize:"contain"}}
                    id="horse-card"
                  >

                  <References
                    name={horse.frontmatter.title}
                    birth={horse.frontmatter.birth}
                    breeding={horse.frontmatter.breeding}
                    gender={horse.frontmatter.gender}
                    representing={horse.frontmatter.representing}
                  />
                  </BgImage>
                </>
              )
            })}
          </div>

       </Layout>
      </>
    );
};

export default Reference;

export const AllReferencesQuery = graphql`
  query Referencesquery {
    allMarkdownRemark(filter: {frontmatter: {class: {eq: "REFERENCES"}}}) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            class
            birth
            gender
            breeding
            representing
            img {
              childImageSharp {
                gatsbyImageData(layout: FIXED)
              }
            }
          }
        }
      }
    }
  }
`



