import React from "react";
import { Link } from "gatsby";
import "./horse-cards.css";

const References = ({ name, birth, breeding, gender, representing}) => (
  <div className="horse-card-inside">
    <div className="horse-info" id="horse-2">
      <p><span className="blue">BIRTH YEAR: </span>{birth}</p>
      <p><span className="blue"> GENDER: </span>{gender}</p>
      <p><span className="blue">BREEDING: </span>{breeding} </p>
      <p><span className="blue">REPRESENTING: </span>{representing} </p>
    </div>
    <div>
     <p className="horse-name">{name}</p>
    </div>
  </div>
);

export default References;
